import { S3ClientConfig } from '@aws-sdk/client-s3';
import { VBDocument } from '@/services/vertriebsbutler/file';
import { FileCreateInput, FileUploadCompleteInput, FileUploadInitInput } from '@/generated/graphql';

interface UploadFileResult {
  success: boolean;
  err?: unknown;
  data?: File;
}

export type FileUploadConnect = {
  avatar_id?: string;
  project_id?: string;
  contact_id?: string;
  campaign_id?: string;
};

type FileHandlerUploadInitInput = Omit<FileUploadInitInput, 'multipart'> & {
  s3Key: string;
  contentType: string;
};
type FileHandlerUploadCompleteInput = Omit<FileUploadCompleteInput, 'multipart'>;
export type UploadFileParams = FileHandlerUploadInitInput &
  Partial<Omit<FileHandlerUploadCompleteInput, 's3key' | 'filename'>> & {
    Body: File;
    customCompleteJob?: boolean;
  };

const fileSizeThreshold = 5 * 1024 * 1024;
const chunkSize = 5 * 1024 * 1024;

export default class FileHandler {
  private bucket: string;
  private cfg: S3ClientConfig;

  private constructor(bucket: string, config: S3ClientConfig) {
    this.bucket = bucket;
    this.cfg = config;
  }

  static async init(dest_bucket?: string) {
    const region = 'eu-central-1';
    const bucket = dest_bucket || window.localStorage.getItem('bucket');

    const cfg = {
      region,
    };
    return new FileHandler(bucket || '', cfg);
  }

  // private uploadFileWithProgress = (
  //   url: string,
  //   file: File,
  //   onProgress: (progress: number) => void,
  // ): Promise<XMLHttpRequest> => {
  //   return new Promise((resolve, reject) => {
  //     const xhr = new XMLHttpRequest();
  //     xhr.open('PUT', url, true);
  //     xhr.setRequestHeader('Content-Type', 'application/octet-stream');
  //     xhr.upload.onprogress = (event) => {
  //       if (event.lengthComputable) {
  //         const progress = (event.loaded / event.total) * 100;
  //         onProgress(progress);
  //       }
  //     };
  //     xhr.onload = () => {
  //       if (xhr.status >= 200 && xhr.status < 300) {
  //         resolve(xhr);
  //       } else {
  //         reject(new Error(`Upload failed with status ${xhr.status}`));
  //       }
  //     };
  //     xhr.onerror = () => reject(new Error('Upload failed due to a network error'));
  //     xhr.send(file);
  //   });
  // };

  private uploadFileWithProgress = (
    url: string,
    s3Key: string,
    file: File,
    uploadId: string | undefined,
    onProgress: (progress: number) => void,
  ): Promise<undefined | Array<{ ETag: string; PartNumber: number }>> => {
    if (!uploadId) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', url, true);
        xhr.setRequestHeader('Content-Type', 'application/octet-stream');
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100;
            onProgress(progress);
          }
        };
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(undefined);
          } else {
            reject(new Error(`Upload failed with status ${xhr.status}`));
          }
        };
        xhr.onerror = () => reject(new Error('Upload failed due to a network error'));
        xhr.send(file);
      });
    } else {
      return new Promise(async (resolve, reject) => {
        try {
          const totalChunks = Math.ceil(file.size / chunkSize);
          let uploadedBytes = 0;

          const uploadPromises = [];
          for (let partNumber = 1; partNumber <= totalChunks; partNumber++) {
            const start = (partNumber - 1) * chunkSize;
            const end = Math.min(partNumber * chunkSize, file.size);
            const chunk = file.slice(start, end);

            const uploadPromise = this.uploadMultipart(s3Key, partNumber, uploadId, chunk).then(
              (part) => {
                uploadedBytes += chunk.size;
                const progress = (uploadedBytes / file.size) * 100;
                onProgress(progress);
                return part;
              },
            );

            uploadPromises.push(uploadPromise);
          }

          const parts = await Promise.all(uploadPromises);
          resolve(parts);
        } catch (error) {
          reject(new Error(`Multipart upload failed: ${error}`));
        }
      });
    }
  };

  upload = async (data: UploadFileParams, onProgress?: (progress: number) => void) => {
    const { Body, backgroundProcess, awsTags, customCompleteJob, ...rest } = data;
    const isMultipartUpload = Body.size > fileSizeThreshold;

    const init = await VBDocument.uploadInit({ ...rest, multipart: isMultipartUpload });
    if (!init.data) throw new Error('Failed to initiate upload');
    const { res } = init.data;
    if (res.id) data.awsTags?.push({ name: 'file_id', value: res.id.toString() });
    try {
      const parts = await this.uploadFileWithProgress(
        res.url!,
        data.s3Key,
        data.Body,
        res.multipartUploadId!,
        (progress) => {
          onProgress?.(progress);
        },
      );

      // if (!data.preInsert)
      //   return {
      //     success: true,
      //     multipart: {
      //       parts: parts || [],
      //       uploadId: res.multipartUploadId,
      //     },
      //   };
      if (customCompleteJob) {
        return {
          success: true,
          data: {
            url: res.url,
            s3key: data.s3Key,
            name: data.Body.name,
            multipart: {
              parts: parts || [],
              uploadId: res.multipartUploadId,
            },
          },
        };
      }
      await VBDocument.completeUpload({
        contentType: data.Body.type,
        filename: data.Body.name,
        s3key: data.s3Key,
        multipart: {
          parts: parts || [],
          uploadId: res.multipartUploadId,
        },
        backgroundProcess,
      });
      return {
        success: true,
        data: {
          url: res.url,
          s3key: data.s3Key,
          name: data.Body.name,
          multipart: {
            parts: parts || [],
            uploadId: res.multipartUploadId,
          },
        },
      };
    } catch (err) {
      if (isMultipartUpload) await this.abortMultipartUpload(data.s3Key, res.multipartUploadId!);
      throw new Error(`Multipart upload failed: ${err}`);
    }
  };

  getUrl = (key: string) => {
    return `https://${this.bucket}.s3.${this.cfg.region}.amazonaws.com/${key}`;
  };

  download = async (filename: string, key: string, mime: string): Promise<UploadFileResult> => {
    const res = await VBDocument.presignedUrlGet({ key });
    if (!res.data) throw new Error('No signed url');
    const res2 = await (await fetch(res.data.url)).blob();
    const file = new File([res2], filename, { type: mime });
    return { success: true, data: file };
  };

  initMultipart = async (input: FileHandlerUploadInitInput) => {
    return await VBDocument.uploadInit({ ...input, multipart: true });
  };

  uploadMultipart = async (
    s3Key: string,
    partNumber: number,
    uploadId: string,
    body: Blob,
  ): Promise<{ ETag: string; PartNumber: number }> => {
    const { data } = await VBDocument.signedMultipartUrl({
      s3Key,
      uploadId,
      partNumber,
    });
    if (!data?.res) throw new Error('No signed url');
    const res = await fetch(data.res, {
      method: 'PUT',
      body,
      credentials: 'same-origin',
    });
    if (!res.ok) {
      throw new Error(`Failed to upload part: ${res.statusText}`);
    }

    const ETag = res.headers.get('etag');
    if (!ETag) {
      console.warn('No ETag header found, this might cause issues when completing the upload.');
    }

    return { ETag: ETag || '', PartNumber: partNumber };
  };

  completeMultipartUpload = async (input: FileUploadCompleteInput, file: FileCreateInput) => {
    await VBDocument.createOne(file);
    return await VBDocument.completeUpload(input);
  };

  abortMultipartUpload = async (key: string, uploadId: string) => {
    return await VBDocument.abortMultipartUpload({
      key,
      uploadId,
    });
  };
}
