import { VBParameter } from '@/services/vertriebsbutler/parameter';
import { VBUser } from '@/services/vertriebsbutler/user';
import { UserState } from '@/types/initialState';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js';

export const getCognitoDataFromStorage = (): Promise<CognitoData | undefined> => {
  const identity_pool_id = window.localStorage.getItem('identity_pool_id');
  const bucket = window.localStorage.getItem('bucket');
  const user_id = window.localStorage.getItem('user_id');
  const auth_user_pool_id = window.localStorage.getItem('auth_user_pool_id');
  const auth_client_id = window.localStorage.getItem('auth_client_id');
  const client_id = window.localStorage.getItem('client_id');
  const user_pool_id = window.localStorage.getItem('user_pool_id');
  if (!auth_user_pool_id || !auth_client_id) {
    return Promise.resolve({
      session: undefined,
      user_pool_id: user_pool_id || '',
      client_id: client_id || '',
      identity_pool_id: identity_pool_id || '',
      bucket: bucket || '',
    });
  }

  const cognitoUserPool = new CognitoUserPool({
    UserPoolId: auth_user_pool_id,
    ClientId: auth_client_id,
  });

  const cognito = cognitoUserPool.getCurrentUser();
  return new Promise<CognitoData | undefined>((resolve) => {
    if (!cognito) {
      resolve({
        session: undefined,
        user_pool_id: user_pool_id || '',
        client_id: client_id || '',
        auth_client_id,
        auth_user_pool_id,
        identity_pool_id: identity_pool_id || '',
        bucket: bucket || '',
        user_id: user_id || '',
        user_name: '',
      });
      return;
    }
    cognito.getSession((err: unknown, ses: CognitoUserSession) => {
      if (err) {
        console.info(err);
        resolve(undefined);
      } else {
        resolve({
          session: ses,
          user_pool_id: user_pool_id || '',
          client_id: client_id || '',
          auth_client_id,
          auth_user_pool_id,
          identity_pool_id: identity_pool_id || '',
          bucket: bucket || '',
          user_id: user_id || '',
          user_name: cognito.getUsername(),
        });
      }
    });
  });
};

class CognitoNewPassword {
  newPassword: boolean;
  currentUser: CognitoUser | null;

  constructor(newPassword: boolean, currentUser: CognitoUser | null) {
    this.newPassword = newPassword;
    this.currentUser = currentUser;
  }
}

export class CognitoService {
  private userPoolId: string;
  private authUserPoolId: string;
  private authClientId: string;
  private userPool: CognitoUserPool | null = null;
  private currentUser: CognitoUser | null | undefined = null;
  private sessAttr: unknown[] = [];

  constructor(
    userPoolId: string,
    clientId: string,
    authUserPoolId?: string,
    authClientId?: string,
  ) {
    this.userPoolId = userPoolId;
    this.authUserPoolId = authUserPoolId || userPoolId;
    this.authClientId = authClientId || clientId;
    this.initializeUserPool();
    this.currentUser = this.userPool?.getCurrentUser();
  }

  public isGlobalUserSession() {
    return this.authUserPoolId !== this.userPoolId;
  }

  private initializeUserPool() {
    if (this.authUserPoolId && this.authClientId) {
      const poolData = {
        UserPoolId: `${this.authUserPoolId}`,
        ClientId: `${this.authClientId}`,
      };
      this.userPool = new CognitoUserPool(poolData);
    }
  }

  public getCurrentUser() {
    return this.currentUser;
  }

  private getCognitoUser(username: string) {
    if (this.userPool) {
      const userData = {
        Username: username,
        Pool: this.userPool,
      };
      return new CognitoUser(userData);
    }
    throw new Error('User pool is not initialized');
  }

  public async getSession() {
    if (!this.currentUser) {
      this.currentUser = this.userPool?.getCurrentUser();
    }

    return new Promise<CognitoUserSession | undefined>((resolve, reject) => {
      if (!this.currentUser) {
        console.info('no current user');
        resolve(undefined);
        return;
      }
      this.currentUser.getSession((err: unknown, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  public async getAttributes() {
    return new Promise<CognitoUserAttribute[] | undefined>((resolve, reject) => {
      if (!this.currentUser) {
        resolve(undefined);
        return;
      }
      this.currentUser.getUserAttributes(
        (err: unknown, attributes: CognitoUserAttribute[] | undefined) => {
          if (err) {
            reject(err);
          } else {
            resolve(attributes);
          }
        },
      );
    }).catch((err) => {
      throw err;
    });
  }

  public async getUserData() {
    const attr = await this.getAttributes();
    if (attr) {
      const accessLevel = parseInt(
        attr?.find((a) => a.Name === 'custom:accessRights')?.Value || '0',
      );
      const cognito_user_id = attr?.find((a) => a.Name === 'sub')?.Value || 'invalid_user_id';
      const { data } = await VBUser.selectOne(cognito_user_id);
      localStorage.setItem('user_id', data?.id || '');
      const param = await VBParameter.selectManyByAgent();
      if (data && param) {
        return {
          role: this.isGlobalUserSession()
            ? UserState.user
            : accessLevel === 1
              ? UserState.su
              : UserState[data.role as keyof typeof UserState] || UserState.unknown,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          id: data.id,
          cognito_user_id,
          isGlobalUser: this.isGlobalUserSession(),
          parameter: param.data?.parameter || [],
        };
      }
    }
    return {
      role: UserState.unknown,
      parameter: [],
      isGlobalUser: false,
    };
  }

  public async setAttribute(attribute: ICognitoUserAttributeData) {
    return new Promise((resolve, reject) => {
      const attributeList = [];
      const res = new CognitoUserAttribute(attribute);
      attributeList.push(res);

      if (!this.currentUser) {
        reject('no current user');
        return;
      }
      this.currentUser.updateAttributes(attributeList, (err: unknown, res: string | undefined) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  // private async signUpUserWithEmail(username: string, email: string, password: string) {
  //   return new Promise((resolve, reject) => {
  //     const attributeList = [
  //       new CognitoUserAttribute({
  //         Name: 'email',
  //         Value: email,
  //       }),
  //     ];

  //     this.userPool?.signUp(username, password, attributeList, [], (err, res) => {
  //       if (err) {
  //         reject(err);
  //       } else {
  //         resolve(res);
  //       }
  //     });
  //   }).catch((err) => {
  //     throw err;
  //   });
  // }

  public async verifyCode(username: string, code: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(username);

      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  public async signInWithEmail(username: string, password: string) {
    return new Promise<CognitoUserSessionRes>((resolve, reject) => {
      const authenticationData = {
        Username: username,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);

      this.currentUser = this.getCognitoUser(username);
      this.currentUser?.authenticateUser(authenticationDetails, {
        onSuccess: function (res: CognitoUserSession) {
          resolve({ session: res });
        },
        onFailure: function (err: unknown) {
          reject(err);
        },
        mfaRequired: (codeDeliveryDetails) => {
          // MFA is required to complete user authentication.
          // Get the code from user and call
          // currentUser.sendMFACode(mfaCode, this)
          console.error('not implemented', codeDeliveryDetails);
          reject('not implemented');
        },
        newPasswordRequired: (_, attr) => {
          if (!this.currentUser) {
            reject('no current user');
            return;
          }

          const data = new CognitoNewPassword(true, this.currentUser);

          this.sessAttr = attr;
          resolve({ newPassword: data.newPassword });
        },
      });
    });
  }

  public async signIn(username: string, password: string) {
    try {
      localStorage.setItem('auth_user_pool_id', this.authUserPoolId);
      localStorage.setItem('auth_client_id', this.authClientId);
      return {
        auth: await this.signInWithEmail(username, password),
        service: undefined,
      };
    } catch (err) {
      if (username.includes('@digital-ateam.de') && this.authUserPoolId !== ADMIN_USER_POOL) {
        const service = new CognitoService(
          this.authUserPoolId,
          this.authClientId,
          ADMIN_USER_POOL,
          ADMIN_CLIENT_ID,
        );
        localStorage.setItem('auth_user_pool_id', ADMIN_USER_POOL);
        localStorage.setItem('auth_client_id', ADMIN_CLIENT_ID);
        return {
          auth: await service.signInWithEmail(username, password),
          service,
        };
      }
      return undefined;
    }
  }

  public signOut() {
    if (this.currentUser) {
      localStorage.removeItem('auth_user_pool_id');
      localStorage.removeItem('auth_client_id');
      this.currentUser.signOut();
    }
  }

  public async sendCode(username: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(username);

      if (!cognitoUser) {
        reject(`could not find ${username}`);
        return;
      }

      cognitoUser.forgotPassword({
        onSuccess: function (res) {
          resolve(res);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    }).catch((err) => {
      throw err;
    });
  }

  public async confirmPassword(username: string, code: string, password: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(username);

      if (!cognitoUser) {
        reject(`could not find ${username}`);
        return;
      }

      cognitoUser.confirmPassword(code, password, {
        onSuccess: function () {
          resolve('password updated');
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  }

  public async changePassword(oldPassword: string, newPassword: string) {
    return new Promise((resolve, reject) => {
      if (!this.currentUser) {
        reject('no current user');
        return;
      }
      this.currentUser.changePassword(
        oldPassword,
        newPassword,
        (err: unknown, res: string | undefined) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        },
      );
    });
  }

  public async changeTempPassword(newPassword: string) {
    return new Promise((resolve, reject) => {
      if (this.currentUser) {
        this.currentUser.completeNewPasswordChallenge(newPassword, this.sessAttr, {
          onSuccess: function () {
            resolve('password updated');
          },
          onFailure: function (err) {
            reject(err);
          },
        });
      }
    });
  }
}
interface CognitoData {
  session?: CognitoUserSession;
  user_pool_id: string;
  client_id: string;
  auth_client_id?: string;
  auth_user_pool_id?: string;
  identity_pool_id: string;
  bucket: string;
  user_id?: string;
  user_name?: string;
}

type CognitoUserSessionRes = {
  session?: CognitoUserSession;
  newPassword?: boolean;
  isGlobalUser?: boolean;
  auth_user_pool?: string;
  auth_client_id?: string;
};
